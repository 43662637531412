import React from 'react';

function Header() {
    return (
        <div>
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <a className="navbar-brand" href="/">
                    &nbsp;&nbsp;&nbsp;
                    <img src='icons/48.png' alt="mufleet"/>
                </a>
                <a className="navbar-brand" href="/">
                    <h1 className="fs-1">mufleet</h1>
                </a>
                <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                >
                    <span className="navbar-toggler-icon"></span>
                </button>
            </nav>
            <br />
        </div>
    );
}

export default Header;
