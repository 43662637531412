import './App.css';
import Header from './shared/Header';
import Footer from './shared/Footer';
import React from 'react';

function App() {
  return (
        <div className="container-md">
            <Header/>
                <div className="row">
                    <div className="col-md-3">
                    </div>
                    <div className="col-md-6">
                      <iframe title="googleform"
                            src="https://docs.google.com/forms/d/e/1FAIpQLSe_-_IIU_tcrF_T6crDvBbCftGiGbdyytqRB5aSuvdmrAHHaQ/viewform?embedded=true"
                            width="100%" height="600" frameBorder="0" marginHeight="0" marginWidth="0">Loading…</iframe>
                    </div>
                    <div className="col-md-3">
                    </div>
                </div>

            <Footer/>
        </div>
  );
}

export default App;
