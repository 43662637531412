import React from 'react';

function Footer() {
    return (
        <footer className="footer mt-auto py-3">
            <br />
            <br />
            <div className="container pb-1 pb-lg-5 text-lg-center">
                <p className="small">2024©mufleet.com</p>
                <p className="small">Email : contact@mufleet.com</p>
            </div>
        </footer>
    );
}

export default Footer;
